import { styled } from "@mui/material";

export const PageLinks = styled('div')(() => ({
    '& ul': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '& li': {
            listStyle: 'none',
            marginTop: '10px',
            '& a': {
                textDecoration: 'none',
                color: '#0C0C0C',
                weight: '600',
                fontSize: '18px',
                transtition: 'all 0.3s ease',
                '&:hover': {
                    color: 'grey'
                }
            }
        }
    }
}));

export const SocialLinks = styled('div')(() => ({
    marginTop: '46px',
    '& a': {
        marginRight: '10px',
        textDecoration: 'none',
        transtition: 'all 0.3s ease',
        '&:hover': {
            opacity: '0.8'
        }
    },
    '& img': {
        width: '32px',
        height: '32px'
    }
}));

export const Information = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
}));

export const Phone = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    '& a': {
        textDecoration: 'none',
        color: '#0C0C0C',
        transtition: 'all 0.3s ease',
        '&:hover': {
            color: 'grey'
        }
    }
}));

export const Contacts = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
}));

export const Footer = styled('footer')(() => ({
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '312px',
    padding: '57px 0 79px 0',
    backgroundColor: '#FFF',
    borderRadius: '34px 34px 0px 0px' 
}));