import vk from '../../resources/icons/social/vk_white.png';
import youtube from '../../resources/icons/social/youtube_white.png';
import telegram from '../../resources/icons/social/telegram_white.png';
import config from '../../config';

export const links = [
    {
        title: 'Главная',
        path: '/',
    },
    {
        title: 'Портфолио',
        path: '/portfolio',
    },
    {
        id: 'pres',
        title: 'Презентация',
        path: '/pres.pptx'
    },
];

export const socials = [
    {
        alt: 'vk',
        src: vk,
        link: config.socials.vk,
    },
    {
        alt: 'telegram',
        src: telegram,
        link: config.socials.telegram,
    },
    {
        alt: 'youtube',
        src: youtube,
        link: config.socials.youtube,
    },
];