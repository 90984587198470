const config = {
    phone: {
        href: "tel:+7(987)183-27-40",
        text: "8 987 183 27 40",
    },
    email: {
        href: "mailto:polighty@yandex.ru",
        text: "polighty@yandex.ru",
    },
    socials: {
        vk: "https://vk.com/po_lighty",
        telegram: "https://web.telegram.org/a/#842648307",
        youtube: 'https://www.youtube.com/@polightyprod'
    },
};

export default config;
