import menuBg from "../../../resources/img/menu/menu_bg.png";

import { styled } from "@mui/material";

export const StyledMenu = styled("div")(() => ({
    position: "fixed",
    zIndex: "999",
    padding: "51px 0",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    background: `url('${menuBg}') center center / cover no-repeat`,
}));
