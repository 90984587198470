import { pages } from "./pages";
import { socials } from "./socials";
import { contacts } from "./contacts";

import Container from "@mui/material/Container";
import {
    Contacts,
    Information,
    PageLinks,
    Phone,
    SocialLinks,
    Footer as FooterComponent,
} from "./styles/styles";
import { Box, Grid, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
    const theme = useTheme();

    const socialLinks = socials.map(({ name, href, src }, index) => (
        <a key={index} href={href}>
            <img src={src} alt={name} />
        </a>
    ));

    const pageLinks = pages.map(({ title, href, id }, index) => (
        <li key={index}>
            {id && id === "pres" ? (
                <a href={href}>{title}</a>
            ) : (
                <Link to={href}>{title}</Link>
            )}
        </li>
    ));

    const contactsView = contacts.map(
        ({ title, href, icon, text, marginRight }, index) => (
            <Box
                key={index}
                sx={{ mt: "25px" }}
                display={"flex"}
                alignItems={"center"}
            >
                <img
                    src={icon}
                    style={{ marginRight }}
                    alt={title}
                    key={index}
                />
                <a href={href}>{text}</a>
            </Box>
        )
    );

    return (
        <FooterComponent>
            <Container maxWidth="xl">
                <Information>
                    <Grid container>
                        <Grid item xs={12} md={9} sm={12}>
                            <Box>
                                <PageLinks>
                                    <ul>{pageLinks}</ul>
                                </PageLinks>
                            </Box>
                        </Grid>
                        <Grid
                            sx={{
                                [theme.breakpoints.down("md")]: {
                                    ml: "40px",
                                    mb: "20px",
                                },
                            }}
                            item
                            xs={12}
                            md={3}
                            sm={12}
                        >
                            <Phone>
                                <Contacts>{contactsView}</Contacts>
                            </Phone>
                            <SocialLinks>{socialLinks}</SocialLinks>
                        </Grid>
                    </Grid>
                </Information>
            </Container>
        </FooterComponent>
    );
};

export default Footer;
