import logo from "../../resources/icons/logo.png";
import phone from "../../resources/icons/contacts/call_white.svg";
import menu from "../../resources/icons/menu.svg";
import close from "../../resources/icons/close.svg";

import { Box, Container, useTheme } from "@mui/material";
import { Phone, Header as HeaderComponent } from "./styles/styles";
import { useDispatch } from "react-redux";
import { toggleMenu } from "../../redux/slices/menuSlice";
import {useState} from 'react';

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import config from "../../config";


const Header = () => {
    const theme = useTheme();

    const [isMenuActive, setIsMenuActive] = useState(true);

    const isMenuOpen = useSelector((state) => state.isMenuOpen);

    const dispatch = useDispatch();

    const handleToggleMenu = () => {
        dispatch(toggleMenu());
        setIsMenuActive(false);
        setTimeout(() => {
            setIsMenuActive(true);
        }, 500);
    }

    return (
        <HeaderComponent>
            <Container sx={{maxWidth: '1655px !important'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Box>
                        <Link to={'/'}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </Box>
                    <Box>
                        <Phone>
                            <Box component={'a'} href={config.phone.href} sx={{marginRight: '100px', [theme.breakpoints.down('sm')]: {mr: '25px'}}} display='flex' justifyContent='center'  alignItems='center'>
                                <img src={phone} style={{marginRight: '20px'}} alt={'phone'} />
                                <Box component={'p'} sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>{config.phone.text}</Box>
                            </Box>
                            <Box>
                                <Box component={'button'} onClick={isMenuActive ? handleToggleMenu : null}>
                                    {isMenuOpen ? <img src={close} alt={'close'} /> : <img src={menu} alt={'menu'} />}
                                </Box>
                            </Box>
                        </Phone>
                    </Box>
                </Box>
            </Container>
        </HeaderComponent>
    );
};

export default Header;