import { styled } from "@mui/material";


export const Header = styled('header')(() => ({
    position: 'absolute',
    zIndex: '9999',
    top: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '100px',
    paddingTop: '30px',
}));

export const Phone = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    '& a, button': {
        textDecoration: 'none',
        border: 'none',
        background: 'transparent',
        color: '#FFF',
        fontWeight: '600',
        transtition: 'all 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            opacity: '0.8'
        }
    },
    '& img': {
        marginRight: '10px',
        width: '24px',
        height: '24px',
    }
}));