import config from '../../config';
import phone from '../../resources/icons/contacts/call.svg';
import email from '../../resources/icons/contacts/mail.svg';

export const contacts = [
    {
        title: 'Phone',
        href: config.phone.href,
        icon: phone,
        marginRight: '31px',
        text: config.phone.text,
    }, 
    {
        title: 'Email',
        href: config.email.href,
        icon: email,
        marginRight: '29px',
        text: config.email.text,
    }
];
