import { Box, Container } from "@mui/material";
import { StyledMenu } from "./styles/styles";

import { links, socials } from "./menuItems";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../redux/slices/menuSlice";
import { Fragment, useEffect } from "react";

const Menu = () => {
    const dispatch = useDispatch();

    const handleToggleMenu = () => {
        dispatch(toggleMenu());
    };

    useEffect(() => {
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";

        return () => {
            document.body.style.height = "";
            document.body.style.overflowY = "";
        };
    }, []);

    const menuItems = links.map((item, index) => {
        return (
            <Fragment key={index}>
                {item.id && item.id === "pres" ? (
                    <Box
                        component={"a"}
                        href={item.path}
                        onClick={handleToggleMenu}
                    >
                        {item.title}
                    </Box>
                ) : (
                    <Link to={item.path} onClick={handleToggleMenu}>
                        {item.title}
                    </Link>
                )}
            </Fragment>
        );
    });

    const socialItems = socials.map((item, index) => {
        return (
            <Box
                key={index}
                component={"a"}
                href={item?.link}
                sx={{
                    display: "block",
                    marginRight: "10px",
                    textDecoration: "none",
                    transtition: "all 0.3s ease",
                    "&:hover": {
                        cursor: "pointer",
                        opacity: "0.8",
                    },
                }}
            >
                <img src={item.src} alt={item.alt} />
            </Box>
        );
    });

    return (
        <StyledMenu>
            <Container sx={{ maxWidth: "1655px !important" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: "185px",
                        "& a": {
                            textDecoration: "none",
                            color: "#FFF",
                            fontSize: "40px",
                            fontWeight: "700",
                            transition: "all 0.3s ease",
                            textTransform: "uppercase",
                            mt: "50px",
                            "&:hover": {
                                opacity: "0.8",
                                cursor: "pointer",
                            },
                        },
                    }}
                >
                    {menuItems}
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "87px",
                        left: "0px",
                        width: "100%",
                    }}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {socialItems}
                    </Box>
                </Box>
            </Container>
        </StyledMenu>
    );
};

export default Menu;
