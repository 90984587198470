import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMenuOpen: false,
};

const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.isMenuOpen = !state.isMenuOpen;
        },
    },
});

const { reducer, actions } = menuSlice;

export default reducer;
export const { toggleMenu } = actions;
