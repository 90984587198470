import config from '../../config';
import telegram from '../../resources/icons/social/telegram.svg';
import vk from '../../resources/icons/social/vk.svg';
import youtube from '../../resources/icons/social/youtube.png';

export const socials = [
    {
        name: 'telegram',
        href: config.socials.telegram,
        src: telegram
    }, 
    {
        name: 'vk',
        href: config.socials.vk,
        src: vk
    }, 
    {
        name: 'youtube',
        href: config.socials.youtube,
        src: youtube
    }
];