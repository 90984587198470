


export const pages = [
    {
        title: 'Главная',
        href: '/'
    },
    {
        title: 'Портфолио',
        href: '/portfolio'
    },
    {
        id: 'pres',
        title: 'Презентация',
        href: '/pres.pptx'
    },
];